





















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import {
  maxValue,
  minValue,
  required,
  requiredIf,
} from 'vuelidate/lib/validators'
import CODTextInput from '../../components/CODTextInput/index.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import CalendarIcon from '@/app/ui/assets/ics_o_calendar.vue'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import controller from '@/app/ui/controllers/CODController'
import { SubmitCODPaymentSettingRequest } from '@/data/payload/api/CODRequest'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { CODPaymentSetting } from '@/domain/entities/COD'

export interface IForm {
  periodeStart: Date | null
  periodeEnd: Date | null
  feeChargedBy: string
  feeCOD: number | null
}

interface IValidation {
  [key: string]: unknown
}

@Component({
  mixins: [validationMixin],
  components: {
    DateTimePickerV2,
    Button,
    RadioButton,
    CODTextInput,
    CalendarIcon,
    ModalConfirm,
    ModalSuccess,
    LoadingOverlay,
  },
})
export default class CODFeeForm extends Vue {
  controller = controller
  form: IForm = {
    periodeStart: null,
    periodeEnd: null,
    feeChargedBy: '',
    feeCOD: null,
  }

  isModalConfirmVisible = false
  isModalSuccessVisible = false

  detailData = {
    createdAt: '-',
    createdBy: '-',
    updatedAt: '-',
    updatedBy: '-',
  }

  isDefaultCODFee = false
  isCODFeeActive = false

  created(): void {
    if (this.isEditPage) {
      this.getCODFeeDetail()
    }
  }

  get minDate(): Date {
    return new Date(new Date().setDate(new Date().getDate() + 1))
  }

  get periodeStartPlaceholder(): string {
    if (this.form.periodeStart) {
      return Utils.formatDateWithIDLocale(
        this.form.periodeStart.toISOString(),
        'DD/MM/YYYY'
      )
    }
    return 'DD/MM/YYYY'
  }

  get periodeEndPlaceholder(): string {
    if (this.form.periodeEnd) {
      return Utils.formatDateWithIDLocale(
        this.form.periodeEnd.toISOString(),
        'DD/MM/YYYY'
      )
    }
    return 'DD/MM/YYYY'
  }

  get periodeStartErrMsg(): string {
    if (this.isPeriodAlreadyExist) {
      return 'Periode sudah dipakai. Cek lagi'
    }

    return 'Periode mulai harus diisi'
  }

  get periodeEndErrMsg(): string {
    if (!this.isMoreThanPeriodeStart(this.form.periodeEnd)) {
      return 'Periode berakhir harus lebih besar dari periode mulai. Cek lagi'
    }

    if (this.isPeriodAlreadyExist) {
      return 'Periode sudah dipakai. Cek lagi'
    }

    return 'Periode berakhir harus diisi'
  }

  get isPeriodAlreadyExist(): boolean {
    return this.controller.isPeriodAlreadyExist
  }

  get isEditPage(): boolean {
    return this.$route.name !== 'CODPaymentSettingCreate'
  }

  @Validations()
  validations(): IValidation {
    return {
      form: {
        periodeStart: {
          required: requiredIf(() => {
            return !this.isDefaultCODFee
          }),
          isPeriodAlreadyExist: () => {
            return !this.isPeriodAlreadyExist
          },
        },
        periodeEnd: {
          required: requiredIf(() => {
            return !this.isDefaultCODFee
          }),
          isMoreThanPeriodeStart: this.isMoreThanPeriodeStart,
          isPeriodAlreadyExist: () => {
            return !this.isPeriodAlreadyExist
          },
        },
        feeChargedBy: { required },
        feeCOD: {
          required: requiredIf((vm: { feeChargedBy: string }) => {
            return vm.feeChargedBy === 'CUSTOMER'
          }),
          minValue: minValue(0.1),
          maxValue: maxValue(100),
        },
      },
    }
  }

  private getCODFeeDetail(): void {
    const codFeeId: number = parseInt(this.$route.params.codFeeId)

    this.controller.getCODPaymentSetting(codFeeId)
  }

  private isMoreThanPeriodeStart(value: Date | null): boolean {
    if (value && this.form.periodeStart) {
      return value.getTime() >= this.form.periodeStart.getTime()
    }

    return true
  }

  public onSubmit(): void {
    if (!this.isEditPage) {
      this.controller.createCODPaymentSetting(
        new SubmitCODPaymentSettingRequest(
          Utils.formatDateWithIDLocale(
            <string>this.form.periodeEnd?.toISOString(),
            'YYYY-MM-DD'
          ),
          Utils.formatDateWithIDLocale(
            <string>this.form.periodeStart?.toISOString(),
            'YYYY-MM-DD'
          ),
          <number>this.form.feeCOD,
          <string>this.form.feeChargedBy
        )
      )
    } else {
      const codFeeId: number = parseInt(this.$route.params.codFeeId)
      this.controller.updateCODPaymentSetting({
        payload: new SubmitCODPaymentSettingRequest(
          !this.isDefaultCODFee
            ? Utils.formatDateWithIDLocale(
                <string>this.form.periodeEnd?.toISOString(),
                'YYYY-MM-DD'
              )
            : undefined,
          !this.isDefaultCODFee
            ? Utils.formatDateWithIDLocale(
                <string>this.form.periodeStart?.toISOString(),
                'YYYY-MM-DD'
              )
            : undefined,
          <number>this.form.feeCOD,
          <string>this.form.feeChargedBy
        ),
        codFeeId,
      })
    }

    this.isModalConfirmVisible = false
  }

  public backToPreviousPage(): void {
    this.isModalSuccessVisible = false
    this.$router.go(-1)
  }

  @Watch('form.feeCOD')
  onFeeCODChanged(value: number): void {
    if (value) {
      this.form.feeCOD = Number(value.toString().replace(/(?<=\.\d{1})\d/, ''))
    }
  }

  @Watch('controller.isSubmitCODPaymentSettingSuccess')
  onIsSuccessSubmitCODPaymentSettingChanged(data: boolean): void {
    if (data) {
      this.controller.setIsSubmitCODPaymentSettingSuccess(false)
      this.isModalSuccessVisible = true
    }
  }

  @Watch('controller.codPaymentSettingDetailData')
  onCODPaymentSettingDetailDataChanged(data: CODPaymentSetting): void {
    if (data.id) {
      if (data.status === 'EXPIRED' && !data.isDefault) {
        this.backToPreviousPage()
      }
      this.isCODFeeActive = data.status === 'ACTIVE' && !data.isDefault
      this.isDefaultCODFee = data.isDefault || false
      this.form.periodeStart = !this.isDefaultCODFee
        ? new Date(<string>data.periodeStart)
        : null
      this.form.periodeEnd = !this.isDefaultCODFee
        ? new Date(<string>data.periodeEnd)
        : null
      this.form.feeCOD = data.feeCod || null
      this.form.feeChargedBy = <string>data.feeChargedBy

      this.detailData.createdAt = Utils.formatDateWithIDLocale(
        <string>data.createdAt,
        'dddd, DD MMMM YYYY, HH:mm'
      )
      this.detailData.updatedAt = Utils.formatDateWithIDLocale(
        <string>data.updatedAt,
        'dddd, DD MMMM YYYY, HH:mm'
      )
      this.detailData.createdBy = data.createdBy || '-'
      this.detailData.updatedBy = data.updatedBy || '-'
    }
  }
}
